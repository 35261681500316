'use client';

import MainLayout from 'src/layouts/main';
import { useSearchParams } from 'src/routes/hooks';
/* eslint-disable react-hooks/exhaustive-deps */

import { Stack } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { _filters } from 'src/_mock';
import { useGetEvents, useGetLiveEvents } from 'src/api/events';
import { useGeolocated } from 'src/hooks/use-geo-localisation';
import { useResponsive } from 'src/hooks/use-responsive';
import { DateFilter, PriceFilter } from 'src/models';
import { useLocationStore } from 'src/stores/ useLocationStore';
import { getDateRange, getPriceFilter } from 'src/utils';

import EventsView from '../events-view';
import EventsDesktopView from '../events-view-desktop';
import FiltersBar from '../filters-bar';

interface ICoords {
  lat: number;
  lng: number;
}

export default function HomeView() {
  const searchParams = useSearchParams();
  const { lat, lng, place, setLat, setLng, setPlace } = useLocationStore();

  const mdDown = useResponsive('down', 'md');

  const cachedCoords: ICoords = useMemo(() => {
    const oldCoords = localStorage?.getItem('coords');
    return oldCoords && JSON.parse(oldCoords);
  }, []);

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: Infinity,
    },
    userDecisionTimeout: 100,
  });

  const addQuery = useCallback(
    (location: { lat: number; lng: number; place?: string }) => {
      setLat(location?.lat.toString());
      setLng(location?.lng.toString());
      setPlace(place);
    },
    [place, setLat, setLng, setPlace]
  );

  const success = useCallback(
    (pos: any) => {
      addQuery({
        lat: pos?.latitude,
        lng: pos?.longitude,
      });
      localStorage.setItem(
        'coords',
        JSON.stringify({
          lat: pos?.latitude,
          lng: pos?.longitude,
        })
      );
    },
    [addQuery]
  );

  useEffect(() => {
    if (!lat && coords?.latitude && coords?.latitude !== cachedCoords?.lat) {
      success(coords);
    }
  }, [coords, cachedCoords, searchParams, lat]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const isMapDisplayed = searchParams.get('map') === 'true';
  const priceParam = (searchParams.get('price') as PriceFilter) ?? undefined;

  const { events, eventsLoading } = useGetEvents({
    tags: searchParams.get('tags'),
    latitude: lat ?? cachedCoords?.lat,
    longitude: lng ?? cachedCoords?.lng,
    ...getDateRange((searchParams.get('date') as unknown as DateFilter) ?? undefined),
    ...getPriceFilter(priceParam),
    take: 300,
  });
  const { liveEvents, liveEventsLoading } = useGetLiveEvents({
    tags: searchParams.get('tags'),
    latitude: lat ?? cachedCoords?.lat,
    longitude: lng ?? cachedCoords?.lng,
    ...getDateRange((searchParams.get('date') as unknown as DateFilter) ?? undefined),
    ...getPriceFilter(priceParam),
    isMapDisplayed,
    take: 300,
  });

  return (
    <MainLayout withProfile>
      <Stack
        sx={{
          pt: { xs: 10, md: '155px' },
          height: '100%',
          flex: 1,
        }}
      >
        {mdDown && <FiltersBar filters={_filters} />}
        {mdDown ? (
          <EventsView
            events={events}
            liveEvents={liveEvents}
            liveEventsLoading={liveEventsLoading}
            eventsLoading={eventsLoading}
          />
        ) : (
          <EventsDesktopView
            events={events}
            liveEvents={liveEvents}
            liveEventsLoading={liveEventsLoading}
            eventsLoading={eventsLoading}
          />
        )}
      </Stack>
    </MainLayout>
  );
}
